import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import pt from 'vuetify/lib/locale/pt';
import Theme from 'Support/Configs/Theme.js';

Vue.use(Vuetify);

const currentLang = localStorage.getItem('lang') ?? 'pt';
const currentTheme = localStorage.getItem('theme') ?? 'dark';

export default new Vuetify({
  theme: {
    dark: currentTheme === 'dark',
    themes: {
      light: Theme.light,
      dark: Theme.dark,
    },
  },
    lang: {
      locales: { pt },
      current: currentLang,
    },
});
