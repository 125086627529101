<template>
  <v-app>
    <app-bar></app-bar>
    <app-drawer></app-drawer>
    <config-btn v-if="!isMobile"></config-btn>
    <app-notification></app-notification>
    <home-section id="home-section"></home-section>
    <about-section id="about-section"></about-section>
    <projects-section id="projects-section"></projects-section>
    <jobs-section id="jobs-section"></jobs-section>
    <knowledge-section id="knowledge-section"></knowledge-section>
    <footer-section id="footer-section"></footer-section>
  </v-app>
</template>

<script>
import AppBar from 'Support/Components/Navigation/AppBar.vue';
import AppDrawer from 'Support/Components/Navigation/AppDrawer.vue';
import ConfigBtn from 'Support/Components/Buttons/ConfigBtn.vue';
import AppNotification from 'Support/Components/Others/AppNotification.vue';
import HomeSection from 'App/HomeSection.vue';
import AboutSection from 'App/AboutSection.vue';
import ProjectsSection from 'App/ProjectsSection.vue';
import JobsSection from 'App/JobsSection.vue';
import KnowledgeSection from 'App/KnowledgeSection.vue';
import FooterSection from 'App/FooterSection.vue';

export default {
  name: 'App',
  components: {
    AppBar,
    AppDrawer,
    ConfigBtn,
    AppNotification,
    HomeSection,
    AboutSection,
    ProjectsSection,
    JobsSection,
    KnowledgeSection,
    FooterSection,
  },
};
</script>

<style>
@import url('Support/Styles/Common.css');
@import url('Support/Styles/Animate.css');
body {
  background: #16161a;
  overflow-x: hidden !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #7f5af0;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #2cb67d;
  border-radius: 10px;
}
</style>
