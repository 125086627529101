<template>
  <v-snackbar app right top :color="color" transition="scroll-x-reverse-transition" :timeout="timeout" :value="show" v-model="show">{{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn icon small v-bind="attrs" @click="show = false">
          <v-icon small color="white">mdi-close</v-icon>
        </v-btn>
      </template>
  </v-snackbar>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'AppNotification',
  methods: {
    notify(message, timeout = 3000, color = 'primary') {
      this.timeout = timeout;
      this.message = message;
      this.color = color;
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },
  data: () => ({
    show: false,
    message: 'Hello',
    timeout: 3000,
    color: 'primary',
  }),
  created() {
    Vue.prototype.$AppNotification = this;
  },
};
</script>