<template>
	<v-btn fab small raised text :class="[shouldAnimate, '']">
		<v-icon @click="toggleTheme()">{{ icon }}</v-icon>
	</v-btn>
</template>

<script>
export default {
	props: {
		animate: {
			type: Boolean,
		},
        disableAnimation: {
            type: Boolean,
        },
	},
	computed: {
		icon (){
			return this.$vuetify.theme.dark ? 'mdi-white-balance-sunny' : 'mdi-weather-night';
		},
		shouldAnimate() {
            if (this.disableAnimation) {
                return '';
            }
            
			return this.animate ? 'animate__animated animate__heartBeat animate__delay-1s' : 'invisible';
		}
	},
	methods: {
		toggleTheme() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
			const theme = this.$vuetify.theme.dark ? 'dark': 'light';
			localStorage.setItem('theme', theme);
      const message = theme === 'light' ? 'themeUpdatedToLight' : 'themeUpdatedToDark';
      this.$AppNotification.notify(this.$t(`notifications.${message}`));
		},
	},
};
</script>