<template>
	<section-base primary>
    <template v-slot:sectionContent>
      <div class="footer-section">
        <v-divider></v-divider>
        <v-row no-gutters align="center" class="mt-10">
          <v-col cols="12" sm="12" md="6" xl="6" lg="6" :order="isMobile ? 2 : 1" :class="['d-flex', isMobile ? 'justify-center' : 'justify-start']">
            <span class="paragraph--text">&copy; {{new Date().getFullYear()}} luizadolfo.com</span>
          </v-col>
          <v-col cols="12" sm="12" md="6" xl="6" lg="6" :order="isMobile ? 1 : 2" :class="['d-flex', isMobile ? 'justify-center' : 'justify-end']">
            <contact-btns disableAnimation></contact-btns>
          </v-col>
        </v-row>
      </div>
    </template>
	</section-base>
</template>

<script>
import SectionBase from 'App/SectionBase.vue';
import ContactBtns from 'Support/Components/Buttons/ContactBtns.vue';

export default {
  name: 'FooterSection',
	components: {
		SectionBase,
    ContactBtns,
	},
}
</script>

<style scoped>
.footer-section {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>