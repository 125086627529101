<template>
  <v-btn fab small raised text :class="[shouldAnimate]" @click="toggleLang()">
    <v-icon>mdi-translate</v-icon>
  </v-btn>
</template>

<script>
import i18n from 'Support/Plugins/VueI18n.js';

export default {
	name: 'LangBtn',
	props: {
		animate: {
			type: Boolean,
		},
    disableAnimation: {
      type: Boolean,
    },
	},
  computed: {
    shouldAnimate() {
      if (this.disableAnimation) {
          return '';
      }

			return this.animate ? 'animate__animated animate__heartBeat animate__delay-2s' : 'invisible';
		},
  },
	methods: {
		toggleLang() {
			const current = this.$vuetify.lang.current;
      const lang = this.langs.find(f => f.value === current).next;
      i18n.locale = lang;
      this.$vuetify.lang.current = lang;
      localStorage.setItem('lang', lang);
      this.$AppNotification.notify(this.$t('notifications.languageUpdated'));
		},
	},
	data: () => ({
    langs: [
      {
          value: 'pt',
          next: 'en',
      },
      {
          value: 'en',
          next: 'pt',
      },
    ],
	}),
};
</script>