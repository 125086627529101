<template>
  <v-speed-dial fixed bottom right v-model="fab">
    <template v-slot:activator>
      <v-btn fab v-model="fab" color="primary">
        <v-icon v-if="fab">mdi-close</v-icon>
        <v-icon v-else>mdi-cog-outline</v-icon>
      </v-btn>
    </template>
    <theme-btn disableAnimation></theme-btn>
    <lang-btn disableAnimation></lang-btn>
  </v-speed-dial>
</template>

<script>
import ThemeBtn from 'Support/Components/Buttons/ThemeBtn.vue';
import LangBtn from 'Support/Components/Buttons/LangBtn.vue';

export default {
  name: 'ConfigBtn',
  components: {
    ThemeBtn,
    LangBtn,
  },
  data: () => ({
    fab: false,
  }),
};
</script>