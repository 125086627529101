<template>
	<section-base primary marginTop>
    <template v-slot:sectionContent>
      <div class="knowledge-section-content">
        <div class="title-section mb-5" data-aos="fade-up" data-aos-duration="600">
          <span class="section-headline">{{$t('appBarBtns.knowledge')}}</span>
        </div>
        <span class="paragraph--text" data-aos="zoom-in" data-aos-delay="600" data-aos-duration="600">{{$t('sections.knowledge.description')}}</span>
        <v-row justify="space-between" align="center" class="mt-15">
          <v-col cols="12" sm="12" md="6" xl="6" lg="6" v-for="(knowledge, index) in knowledges" :key="index" data-aos-duration="600" :data-aos="knowledge.animation" :data-aos-delay="(index + 1) * 300">
            <div class="knowledge-section-content-items">
              <img :src="knowledge.icon" :class="[`knowledge-section-content-items-background-${index+1}`, `knowledge-img-floating-${index+1}`]"/>
              <knowledge-card v-for="(item, itemIndex) in knowledge.items" :key="itemIndex" :id="`knowledge-card-${index}-${itemIndex}`" :icon="item.icon" :startColor="knowledge.startColor" :endColor="knowledge.endColor" :left="knowledge.left" @mouseenter.native="showDescription(item.description, knowledge.notificationColor)" @mouseleave.native="hideDescription()"></knowledge-card>
            </div>
          </v-col>
        </v-row>
      </div>
    </template>
  </section-base>
</template>

<script>
import SectionBase from 'App/SectionBase.vue';
import KnowledgeCard from 'Support/Components/Cards/KnowledgeCard.vue';

export default {
  name: 'KnowledgeSection',
	components: {
		SectionBase,
    KnowledgeCard,
	},
  methods: {
    showDescription(description, notificationColor) {
      this.$AppNotification.notify(this.$t(description), 60000, notificationColor);
    },
    hideDescription() {
      this.$AppNotification.close();
    },
  },
  data: () => ({
    knowledges: [
      {
        notificationColor: 'primary',
        animation: 'fade-down-right',
        startColor: '#20BF55',
        endColor: '#01BAEF',
        icon: require('Assets/Icons/vue.svg'),
        items: [
          {
            icon: require('Assets/Icons/vue.svg'),
            description: 'sections.knowledge.items.vue',
          },
          {
            icon: require('Assets/Icons/vuetify.png'),
            description: 'sections.knowledge.items.vuetify',
          },
          {
            icon: require('Assets/Icons/html5.png'),
            description: 'sections.knowledge.items.html5',
          },
          {
            icon: require('Assets/Icons/js.png'),
            description: 'sections.knowledge.items.js',
          },
          {
            icon: require('Assets/Icons/css.png'),
            description: 'sections.knowledge.items.css',
          },
          {
            icon: require('Assets/Icons/jest.png'),
            description: 'sections.knowledge.items.jest',
          },
          {
            icon: require('Assets/Icons/npm.png'),
            description: 'sections.knowledge.items.npm',
          },
          {
            icon: require('Assets/Icons/yarn.png'),
            description: 'sections.knowledge.items.yarn',
          },
          {
            icon: require('Assets/Icons/github.png'),
            description: 'sections.knowledge.items.github',
          },
          {
            icon: require('Assets/Icons/gitlab.png'),
            description: 'sections.knowledge.items.gitlab',
          },
          {
            icon: require('Assets/Icons/sonar.png'),
            description: 'sections.knowledge.items.sonar',
          },
          {
            icon: require('Assets/Icons/grafana.png'),
            description: 'sections.knowledge.items.grafana',
          },
        ],
      },
      {
        notificationColor: 'secondary',
        animation: 'fade-up-left',
        startColor: '#6E72FC',
        endColor: '#AD1DEB',
        icon: require('Assets/Icons/dotnet.svg'),
        left: true,
        items: [
          {
            icon: require('Assets/Icons/dotnet.svg'),
            description: 'sections.knowledge.items.dotnet',
          },
          {
            icon: require('Assets/Icons/csharp.png'),
            description: 'sections.knowledge.items.csharp',
          },
          {
            icon: require('Assets/Icons/efcore.png'),
            description: 'sections.knowledge.items.efcore',
          },
          {
            icon: require('Assets/Icons/nunit.png'),
            description: 'sections.knowledge.items.nunit',
          },
          {
            icon: require('Assets/Icons/identityserver.png'),
            description: 'sections.knowledge.items.identityserver',
          },
          {
            icon: require('Assets/Icons/sqlserver.png'),
            description: 'sections.knowledge.items.sqlserver',
          },
          {
            icon: require('Assets/Icons/postgres.png'),
            description: 'sections.knowledge.items.postgres',
          },
          {
            icon: require('Assets/Icons/mongo.png'),
            description: 'sections.knowledge.items.mongo',
          },
          {
            icon: require('Assets/Icons/redis.png'),
            description: 'sections.knowledge.items.redis',
          },
          {
            icon: require('Assets/Icons/docker.png'),
            description: 'sections.knowledge.items.docker',
          },
          {
            icon: require('Assets/Icons/aws.png'),
            description: 'sections.knowledge.items.aws',
          },
          {
            icon: require('Assets/Icons/rabbitmq.png'),
            description: 'sections.knowledge.items.rabbitmq',
          },
          {
            icon: require('Assets/Icons/consul.png'),
            description: 'sections.knowledge.items.consul',
          },
        ],
      },
    ],
  }),
};
</script>

<style scoped>
.knowledge-section-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.knowledge-section-content-items {
  display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.knowledge-section-content-items-background-1 {
  z-index: 0;
  position: absolute;
  width: 250px;
  opacity: 0.3;
  margin-top: -200px;
  margin-left: -380px;
  animation-name: knowledge-section-content-items-background-animation-1;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.knowledge-section-content-items-background-2 {
  z-index: 0;
  position: absolute;
  width: 250px;
  opacity: 0.3;
  margin-top: 200px;
  margin-left: 380px;
  animation-name: knowledge-section-content-items-background-animation-2;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes knowledge-section-content-items-background-animation-1 {
  from { transform: translate(0px,  0px); }
  50%  { transform: translate(-25px, -25px); }
  to   { transform: translate(0px, 0px); }    
}

@keyframes knowledge-section-content-items-background-animation-2 {
  from { transform: translate(-25px, -25px); }
  50%  { transform: translate(0px, 0px); }
  to   { transform: translate(-25px, -25px); }    
}
</style>
