<template>
  <v-btn icon x-large color="primary" :class="marginRight" @click="openLink()">
    <v-icon large color="primary">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ContactBtn',
  props: {
    link: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    mr: {
      type: String,
    },
  },
  computed: {
    marginRight() {
      return this.mr ? `mr-${this.mr}` : '';
    },
  },
  methods: {
    openLink() {
      if (!this.link) return;
      var win = window.open(this.link, '_blank');
      win.focus();
    },
  },
};
</script>
