<template>
  <v-container fluid class="app-container d-flex align-center justify-center">
    <slot name="appContainerContent"></slot>
  </v-container>
</template>

<script>
export default {
  name: 'AppContainer',
};
</script>

<style scoped>
.app-container {
  width: 1150px !important;
  height: 100% !important;
  padding-left: 20px;
  padding-right: 20px;
}
</style>