<template>
  <div class="project-card backgroundSecondary elevation-10 animate-on-hover">
    <div class="project-card-left d-flex align-center justify-center" :style="backgroundColor">
      <img :src="src" width="65">
    </div>
    <div class="project-card-right pt-5">
      <span class="project-card-title">{{ title }}</span>
      <span class="project-card-description text-justify paragraph--text pl-5 pr-5 pt-5">{{ description }}</span>
      <v-spacer></v-spacer>
      <div class="fill-width project-card-btn d-flex align-start justify-end">
        <v-btn small text :color="color" @click="openLink()" class="text-capitalize">{{ $t('components.projectCard.btn') }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectCard',
  props: {
    color: {
      type: String,
      default: '#183355',
    },
    link: {
      type: String,
      required:true,
    },
    title: {
      type: String,
      required:true,
    },
    description: {
      type: String,
      required:true,
    },
    src: {
      type: String,
      required: true,
    },
  },
  computed: {
    backgroundColor() {
      return `background-color: ${this.color};`;
    },
  },
  methods: {
    openLink() {
      window.open(this.link);
    },
  },
};
</script>

<style scoped>
.project-card {
  width: 100%;
  height: 220px;
  border-radius: 5px;
  display: flex;
  flex-direction: row; 
}

.project-card-left {
  width: 100px;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.project-card-right {
  width: 100%;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.project-card-title {
  font-size: 1.2rem;
  font-weight: 800;
}

.project-card-description {
  font-size: 0.9rem;
}

.project-card-btn {
  height: 30px;
}
</style>