import Vue from 'vue';
import App from './App.vue';
import vuetify from 'Support/Plugins/Vuetify';
import ScrollHandler from 'Support/Mixins/ScrollHandler.js';
import i18n from 'Support/Plugins/VueI18n.js';
import AOS from 'aos';
import VueTilt from 'vue-tilt.js'
import 'aos/dist/aos.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false
Vue.mixin(ScrollHandler);
Vue.use(VueTilt);

new Vue({
  i18n,
  vuetify,
  render: function (h) { return h(App) },
  mounted() {
    AOS.init();
  },
}).$mount('#app')
