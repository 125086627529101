<template>
	<section-base primary marginTop>
    <template v-slot:sectionContent>
      <div class="projects-section-content">
        <div class="title-section" data-aos="fade-up" data-aos-duration="600">
          <span class="section-headline">{{$t('appBarBtns.projects')}}</span>
        </div>
        <v-row justify="space-between" align="center" class="mt-15">
          <v-col cols="12" lg="4" xl="4" v-for="project in projects" :key="project.color" :md="project.md" :sm="project.sm" :data-aos-delay="project.delay" data-aos="fade-right" data-aos-duration="1000">
            <project-card :color="project.color" :title="$t(project.title)" :description="$t(project.description)" :link="project.link" :src="project.src"></project-card>
          </v-col>
        </v-row>
        <v-btn text color="primary" class="mt-15 text-capitalize" @click="openGithubRepositories()">
          <v-icon left>mdi-open-in-new</v-icon>
          {{$t('sections.projects.repositoriesBtn')}}
        </v-btn>
      </div>
    </template>
	</section-base>
</template>

<script>
import SectionBase from 'App/SectionBase.vue';
import ProjectCard from 'Support/Components/Cards/ProjectCard.vue';

export default {
  name: 'ProjectsSection',
	components: {
		SectionBase,
    ProjectCard,
	},
  methods: {
    openGithubRepositories() {
      window.open('https://github.com/Adolfok3?tab=repositories');
    }
  },
  data: vm => ({
    projects: [
      {
        title: 'sections.projects.project1.title',
        description: 'sections.projects.project1.description',
        link: 'http://www.ferdadoengenharia.com.br',
        src: require('Assets/Icons/ferdado.png'),
        color: '#e00437',
        delay: '0',
        md: '6',
        sm:'6',
      },
      {
        title: 'sections.projects.project2.title',
        description: 'sections.projects.project2.description',
        link: 'https://vue-termo-game.netlify.app',
        src: require('Assets/Icons/vue.svg'),
        color: '#66e17d',
        delay: '300',
        md: '6',
        sm:'6',
      },
      {
        title: 'sections.projects.project3.title',
        description: 'sections.projects.project3.description',
        link: 'https://github.com/Adolfok3/RequesterNet',
        src: require('Assets/Icons/dotnet.svg'),
        color: '#7f5af0',
        delay: '600',
        md: '12',
        sm:'12',
      },
    ],
  }),
};
</script>

<style scoped>
.projects-section-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
}
</style>