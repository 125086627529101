import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Languages from 'Support/Configs/Languages.js';

Vue.use(VueI18n);

const cacheLocale = localStorage.getItem('lang') ?? 'pt';

const messages = {
  pt: Languages.pt,
  en: Languages.en,
};

export default new VueI18n({
  locale: cacheLocale,
  fallbackLocale: 'pt',
  messages,
});
