<template>
	<section-base marginTop>
    <template v-slot:sectionContent>
      <v-row>
        <v-col xl="6" lg="6" md="6" data-aos="fade-down" data-aos-duration="1600" class="pr-0 pl-0 d-flex align-center" v-if="!isMobile">
          <div class="animate-on-hover">
            <img :src="programmer" class="programmer-img">
            <img :src="vue" class="img-vue img-vue-animation">
            <img :src="dotnet" class="img-dotnet img-dotnet-animation">
          </div>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="12" cols="12" data-aos="fade-down" data-aos-duration="600" class="d-flex align-center pr-0 pl-0 pt-0 pb-0">
          <div>
            <span class="section-headline">{{$t('appBarBtns.whoIam')}}</span>
            <p class="text-justify paragraph--text mt-5">{{$t('sections.whoIAm.description')}}</p>
          </div>
        </v-col>
      </v-row>
    </template>
	</section-base>
</template>

<script>
import SectionBase from 'App/SectionBase.vue';

export default {
	components: {
		SectionBase,
	},
  computed: {
    programmer() {
      return require('Assets/Icons/programmer.svg');
    },
    dotnet() {
      return require('Assets/Icons/dotnet.svg');
    },
    vue() {
      return require('Assets/Icons/vue.svg');
    },
  },
};
</script>

<style scoped>
.programmer-img {
  max-width: 400px;
  width: 100%;
  height: auto;
}
.img-vue {
  position: absolute;
  height: 75px;
  margin-top: 50px;
  margin-left: -380px;
}
.img-dotnet {
  position: absolute;
  height: 75px;
  margin-top: 210px;
  margin-left: -90px;
}

.img-vue-animation {
  animation-name: img-vue-animation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes img-vue-animation {
    0%
    {
      transform: translatex(20px);
    }
    50% 
    {
      transform: translatex(0px);
    }
    100%
    {
      transform: translatex(20px);
    }
}

.img-dotnet-animation {
  animation-name: img-dotnet-animation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes img-dotnet-animation {
    0%
    {
      transform: translatex(-20px);
    }
    50% 
    {
      transform: translatex(0px);
    }
    100%
    {
      transform: translatex(-20px);
    }
}
</style>