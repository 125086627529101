<template>
	<section-base marginTop>
    <template v-slot:sectionContent>
      <div class="jobs-section-content">
        <div class="title-section" data-aos="fade-up" data-aos-duration="600">
          <span class="section-headline">{{$t('appBarBtns.jobs')}}</span>
        </div>
        <v-row justify="space-between" align="center" class="mt-15">
          <v-col cols="12" lg="4" xl="4" :md="job.md" :sm="job.sm" v-for="job in jobs" :key="job.title" :data-aos-delay="job.delay" data-aos="fade-left" data-aos-duration="1000">
            <job-card :startColor="job.startColor" :endColor="job.endColor" :title="$t(job.title)" :icon="job.icon" :iconSize="job.iconSize" :description="$t(job.description)">
              <template v-slot:jobCardIcon>
                <img :src="job.icon" height="150" :class="job.iconClass">
              </template>
            </job-card>
          </v-col>
        </v-row>
      </div>
    </template>
  </section-base>
</template>

<script>
import SectionBase from 'App/SectionBase.vue';
import JobCard from 'Support/Components/Cards/JobCard.vue';

export default {
  name: 'JobsSection',
	components: {
		SectionBase,
    JobCard,
	},
  data: () => ({
    jobs: [
      {
        startColor: '#009FFD',
        endColor: '#2A2A72',
        title: 'sections.jobs.job1.title',
        description: 'sections.jobs.job1.description',
        icon: require('Assets/Icons/ui-ux-design.png'),
        delay: '0',
        md: '6',
        sm:'6',
        iconClass: 'job-card-floating-1'
      },
      {
        startColor: '#20BF55',
        endColor: '#01BAEF',
        title: 'sections.jobs.job2.title',
        description: 'sections.jobs.job2.description',
        icon: require('Assets/Icons/web-developer.png'),
        delay: '300',
        md: '6',
        sm:'6',
        iconClass: 'job-card-floating-2'
      },
      {
        startColor: '#6E72FC',
        endColor: '#AD1DEB',
        title: 'sections.jobs.job3.title',
        description: 'sections.jobs.job3.description',
        icon: require('Assets/Icons/backend-developer.png'),
        iconSize: 150,
        delay: '600',
        md: '12',
        sm:'12',
        iconClass: 'job-card-floating-3'
      },
    ],
  }),
};
</script>

<style scoped>

.jobs-section-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.job-card-floating-1 {
  animation-name: job-card-floating-1;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes job-card-floating-1 {
  from { transform: translate(0px,  0px); }
  50%  { transform: translate(0px, -15px); }
  to   { transform: translate(0px, 0px); }    
}

.job-card-floating-2 {
  animation-name: job-card-floating-2;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes job-card-floating-2 {
  from { transform: translate(0px,  -15px); }
  50%  { transform: translate(0px, 0px); }
  to   { transform: translate(0px, -15px); }
}

.job-card-floating-3 {
  animation-name: job-card-floating-3;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes job-card-floating-3 {
  from { transform: translate(0px,  0px); }
  50%  { transform: translate(0px, -15px); }
  to   { transform: translate(0px, 0px); }
}
</style>