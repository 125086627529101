<template>
	<v-app-bar app elevate-on-scroll id="app-bar" color="transparent" class="app-blur">
    <app-container>
      <template v-slot:appContainerContent>
        <v-row justify="space-between">
          <div class="d-flex align-center hover" @click="scrollToHome()">
            <img :src="logo" alt="Logo" height="45">
            <span class="logo-text text-body-1 headline--text">Luiz
              <br>
              Adolfo
            </span>
          </div>
          <navigation-btns v-if="!isMobile"></navigation-btns>
          <v-app-bar-nav-icon @click.stop="openDrawer()" v-else></v-app-bar-nav-icon>
        </v-row>
      </template>
    </app-container>
	</v-app-bar>
</template>

<script>
import AppContainer from 'Support/Components/Grid/AppContainer.vue';
import NavigationBtns from 'Support/Components/Buttons/NavigationBtns.vue';

export default {
	components: {
    AppContainer,
		NavigationBtns,
	},
  computed: {
    logo() {
      return require('Assets/Logos/logo.svg');
    },
    color() {
      return this.isScrolled ? 'backgroundSecondary' : 'backgroundPrimary';
    },
  },
  methods: {
    openDrawer() {
      this.$AppDrawer.show = true;
    },
    scrollToHome() {
      this.scrollToElement('#home-section');
    },
  },
};
</script>


<style scoped>
.logo-text {
  padding-left: 5px;
  line-height: 18px !important;
}
</style>