<template>
  <div class="job-card elevation-10 animate-on-hover" :style="background">
    <div class="job-card-top">
      <slot name="jobCardIcon">
      </slot>
    </div>
    <div class="job-card-bottom">
      <span class="job-card-title text-justify white--text">{{title}}</span>
      <span class="job-card-description text-justify mt-3 white--text">{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JobCard',
  props: {
    startColor: {
      type: String,
      default: '#632d8e',
    },
    endColor: {
      type: String,
      default: '#944dcd',
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
  },
  computed: {
    background() {
      return `background: linear-gradient(130deg,${this.startColor},${this.endColor}) border-box;`;
    },
  },
};
</script>

<style scoped>
.job-card {
  width: 100%;
  height: 350px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.job-card-top {
  width: 100%;
}

.job-card-bottom {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.job-card-title {
  font-size: 1.2rem;
  font-weight: 800;
}

.job-card-description {
  font-size: 0.9rem;
}

</style>