<template>
  <div class="mx-4" @click="scrollToElementName()">
    <span class="bar-btn underline hover text-capitalize text-body-1 headline--text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'BarBtn',
  props: {
    text: {
      type: String,
      default: 'Button'
    },
    elementName: {
      type: String
    },
  },
  methods: {
    scrollToElementName() {
      if (!this.elementName) {
        return;
      }

      this.scrollToElement(this.elementName);
    },
  },
};
</script>

<style scoped>
.bar-btn {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.underline {
  display: inline;
  position: relative;
  overflow: hidden;
}
.underline:after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  width: 0;
  bottom: -5px;
  background: #2cb67d;
  height: 1.5px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.underline:hover:after,
.underline:focus:after,
.underline:active:after {
  left: 0;
  right: auto;
  width: 100%;
}
</style>