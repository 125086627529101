export default {
  light: {
    primary: '#2cb67d',
    secondary: '#7f5af0',
    headline: '#181818',
    paragraph: '#2e2e2e',
    backgroundPrimary: '#fffffe',
    backgroundSecondary: '#f2f4f6',
  },
  dark: {
    primary: '#2cb67d',
    secondary: '#7f5af0',
    headline: '#fffffe',
    paragraph: '#94a1b2',
    backgroundPrimary: '#16161a',
    backgroundSecondary: '#242629',
  },
};
