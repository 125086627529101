<template>
  <section-base primary screen-height>
    <template v-slot:sectionContent>
      <v-row justify="space-between" class="no-padding" style="z-index: 1;">
        <v-col xl="6" lg="6" md="6" sm="12" cols="12" :class="['pl-0 pr-0', titleClass]">
          <div class="app-subheadline paragraph--text">{{$t('sections.home.greetings')}}</div>
          <div class="app-headline headline--text">Luiz Adolfo</div>
          <vue-auto-writer class="app-subheadline paragraph--text" cursor-color="paragraph" base-text="" :texts="writerTexts"></vue-auto-writer>
          <contact-btns class="mt-5"></contact-btns>
          <div class="logo-mobile" v-if="isMobile">
            <img id="logoElement" :src="logo" alt="Logo" height="300" data-aos="fade-down" data-aos-duration="1600">
          </div>
        </v-col>
        <v-col xl="6" lg="6" md="6" class="d-flex align-center justify-center pl-0 pr-0" v-tilt="{perspective: 600}" v-if="!isMobile">
          <div class="logo-glow"></div>
          <img id="logoElement" :src="logo" alt="Logo" height="300" data-aos="fade-down" data-aos-duration="1600">
        </v-col>
      </v-row>
    </template>
  </section-base>
</template>

<script>
import SectionBase from 'App/SectionBase.vue';
import ContactBtns from 'Support/Components/Buttons/ContactBtns.vue';
import VueAutoWriter from 'vue-auto-writer';

export default {
  name: 'HomeSection',
	components: {
		SectionBase,
    ContactBtns,
    VueAutoWriter,
	},
  computed: {
    titleClass() {
      return !this.isMobile ? '' : 'd-flex flex-column jusitfy-center align-center';
    },
    logo() {
      return require('Assets/Logos/logo.svg');
    },
  },
  mounted() {
    setTimeout(() => {
      document.getElementById('logoElement').classList.add('floating-logo');
    }, 1600);
  },
  data: () => ({
    writerTexts: [
      {
        value: 'UI/UX Design',
      },
      {
        value: 'Front-end developer',
      },
      {
        value: 'Back-end developer',
      },
    ],
  }),
};
</script>

<style scoped>
.logo-glow {
  position: absolute;
  box-shadow: -50px -50px 200px 75px #2cb67d, 50px 50px 200px 75px #7f5af0;
}

.logo-mobile {
  position: absolute;
  z-index: -1;
  opacity: 0.2;
  width: 100%;
  margin-top: -40px;
  display: flex;
  justify-content: center;
}

.floating-logo {
  animation-name: floating-logo-animation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating-logo-animation {
  0%
  {
    transform: translatey(0px);
  }
  50% 
  {
    transform: translatey(-30px);
  }
  100%
  {
    transform: translatey(0px);
  }
}
</style>