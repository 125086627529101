<template>
  <div v-if="mobile">
    <drawer-btn v-for="btn in btns" :key="btn.id" :text="$t(btn.text)" :elementName="btn.elementName" :icon="btn.icon" :class="['animate__lightSpeedInLeft', 'animate__fast', shouldAnimate]" @click="$emit('onCloseDrawer')"></drawer-btn>
  </div>
  <div v-else class="d-flex align-center">
    <v-row no-gutters>
      <bar-btn v-for="btn in btns" :key="btn.id" :text="$t(btn.text)" :elementName="btn.elementName"></bar-btn>
    </v-row>
  </div>
</template>

<script>
import DrawerBtn from 'Support/Components/Buttons/DrawerBtn.vue';
import BarBtn from 'Support/Components/Buttons/BarBtn.vue';

export default {
  name: 'NavigationBtns',
  props: {
    mobile: {
      type: Boolean,
    },
    animate: {
      type: Boolean,
    },
  },
  components: {
    BarBtn,
    DrawerBtn,
  },
  computed: {
    shouldAnimate() {
      return this.animate ? 'animate__animated' : 'invisible';
    },
  },
  data: () => ({
    btns: [
      {
        id: 1,
        text: 'appBarBtns.whoIam',
        icon: 'mdi-card-account-details',
        elementName: '#about-section',
      },
      {
        id: 2,
        text: 'appBarBtns.projects',
        icon: 'mdi-rocket-launch',
        elementName: '#projects-section',
      },
      {
        id: 3,
        text: 'appBarBtns.jobs',
        icon: 'mdi-laptop',
        elementName: '#jobs-section',
      },
      {
        id: 4,
        text: 'appBarBtns.knowledge',
        icon: 'mdi-lightbulb-on',
        elementName: '#knowledge-section',
      },
    ],
  }),
};
</script>