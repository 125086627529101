<template>
  <div>
    <contact-btn v-for="(btn, index) in btns" class="pa-0 ma-0" :data-aos="!disableAnimation ? 'zoom-in' : ''" data-aos-duration="800" :data-aos-delay="250 * index" :key="index" :mr="btn.mr" :icon="btn.icon" :link="btn.link"></contact-btn>
  </div>
</template>

<script>
import ContactBtn from 'Support/Components/Buttons/ContactBtn.vue';

export default {
  name: 'ContactBtns',
  props: {
    disableAnimation: {
      type: Boolean,
    },
  },
  components: {
    ContactBtn,
  },
  data: () => ({
    btns: [
      {
        icon: 'mdi-linkedin',
        link: 'https://www.linkedin.com/in/luiz-adolfo-0b5289195/',
        align: 'start',
        mr: '3',
      },
      {
        icon: 'mdi-github',
        link: 'https://github.com/Adolfok3',
        align: 'end',
        mr: '3',
      },
      {
        icon: 'mdi-stack-overflow',
        link: 'https://stackoverflow.com/users/8298014/adolfok3',
        align: 'start',
        mr: '3',
      },
      {
        icon: 'mdi-email',
        link: 'mailto:luizadolfo.martellilemos@gmail.com',
        align: 'end',
        mr: '3',
      },
      {
        icon: 'mdi-instagram',
        link: 'https://www.instagram.com/luiz.martelli',
        align: 'start',
      },
    ],
  }),
};
</script>
