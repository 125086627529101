const ScrollHandler = {
  name: 'ScrollHandler',
  created () {
    window.addEventListener('scroll', () => {
      this.scroll = document.documentElement.scrollTop;
			this.isScrolled = this.scroll > 0 ? true : false;
    })
  },
  data: () => ({
    scroll: 0,
		isScrolled: false,
  }),
  computed: {
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
  },
  methods: {
    scrollToElement(elementName) {
      const element = document.querySelector(elementName);
      element.scrollIntoView({ behavior: 'smooth', block: 'center'});
    },
  },
};

export default ScrollHandler
