<template>
  <v-list-item link two-line @click="scrollToElementName();">
		<v-icon  class="mr-3">{{ icon }}</v-icon>
		<v-list-item-content>
			<span>{{ text }}</span>
		</v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
	name: 'DrawerBtn',
	props: {
		text: {
			type: String,
			default: 'text'
		},
		icon: {
			type: String,
			default: 'mdi-home'
		},
    elementName: {
      type: String,
      required: true
    },
	},
  methods: {
    scrollToElementName() {
      this.scrollToElement(this.elementName);
      this.$emit('click');
    },
  },
};
</script>