<template>
	<v-navigation-drawer app temporary right v-model="show" overlay-opacity="0" color="transparent" class="app-blur screen-height">
		<v-container>
			<v-row justify="center" class="ma-5">
        <img :src="logo" alt="Logo" height="65">
			</v-row>
		</v-container>
  	<navigation-btns mobile :animate="show" @onCloseDrawer="show = false;"></navigation-btns>
    <v-divider class="mt-1"></v-divider>
    <v-container>
      <v-row justify="center" class="mt-1">
        <theme-btn :animate="show"></theme-btn>
        <lang-btn :animate="show"></lang-btn>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import Vue from 'vue';
import NavigationBtns from 'Support/Components/Buttons/NavigationBtns.vue';
import ThemeBtn from 'Support/Components/Buttons/ThemeBtn.vue';
import LangBtn from 'Support/Components/Buttons/LangBtn.vue';

export default {
  name: 'AppDrawer',
  components: {
    NavigationBtns,
		ThemeBtn,
		LangBtn,
  },
  computed: {
    logo() {
      return require('Assets/Logos/logo.svg');
    },
  },
	data: () => ({
		show: false,
	}),
	created() {
		Vue.prototype.$AppDrawer = this;
	},
};
</script>
