<template>
  <div :id="id" :class="['knowledge-card', sideClass]" :style="customStyle" @mouseenter="addGlowing()" @mouseleave="removeGlowing()">
    <img :src="icon" height="55" class="animate-on-hover-2">
  </div>
</template>

<script>
export default {
  name: 'KnowledgeCard',
  props: {
    id: {
      type: String,
    },
    startColor: {
      type: String,
      default: '#fcba03'
    },
    endColor: {
      type: String,
      default: '#fbac03'
    },
    icon: {
      type: String,
    },
    left: {
      type: Boolean,
    },
  },
  computed: {
    customStyle() {
      return `background: linear-gradient(135deg, ${this.startColor} 0%, ${this.endColor} 100%) border-box;`;
    },
    sideClass() {
      return this.left ? 'knowledge-card-left' : 'knowledge-card-right';
    }
  },
  methods: {
    addGlowing() {
      const element = document.getElementById(this.id);
      if (!element) {
        return;
      };

      element.style.boxShadow = `0px 0px 15px ${this.startColor}`;
    },
    removeGlowing() {
      const element = document.getElementById(this.id);
      if (!element) {
        return;
      };
      element.style.boxShadow = 'none';
    },
  },
};
</script>

<style scoped>
.knowledge-card {
  width: 100px;
  height: 100px;  
  transition: 0.3s;
  border: 0px solid transparent;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.knowledge-card-left {
  transform: skew(-30deg) scale(0.9);
}

.knowledge-card-right {
  transform: skew(30deg) scale(0.9);
}

.knowledge-card:hover {
  z-index: 2;
  transform: skew(0deg) scale(1.5);
}

</style>