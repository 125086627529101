<template>
  <v-container fluid :class="[screenHeightClass, backgroundClass]">
    <app-container :class="marginTop ? 'app-container-padding' : null">
      <template v-slot:appContainerContent>
        <slot name="sectionContent"></slot>
      </template>
    </app-container>
  </v-container>
</template>

<script>
import AppContainer from 'Support/Components/Grid/AppContainer.vue';

export default {
	props: {
    primary: {
      type: Boolean,
    },
    screenHeight: {
      type: Boolean,
    },
    marginTop: {
      type: Boolean,
    },
  },
  components: {
    AppContainer,
  },
	computed: {
    screenHeightClass() {
      return this.screenHeight ? 'screen-height' : 'auto';
    },
    backgroundClass() {
      return this.primary ? 'backgroundPrimary' : 'backgroundSecondary';
    },
	},
};
</script>

<style scoped>
.app-container-padding {
  padding-top: 15vmin;
  padding-bottom: 15vmin;
}
</style>